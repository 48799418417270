<template>
  <div id="report-list">
    <v-card>
      <v-card-title> Reports </v-card-title>

      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-autocomplete
            class="chargeback-report-website-filter"
            v-model="websiteFilter"
            :items="formattedWebsites"
            item-value="id"
            item-text="name"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Website name"
            multiple
            chips
            small-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            class="chargeback-report-service-filter"
            v-model="serviceFilter"
            placeholder="Select Service"
            :items="serviceOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-dialog ref="dialog" v-model="periodDateModal" :return-value.sync="periodDateFilter" width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                class="chargeback-report-date-filter"
                v-model="periodDateFilter"
                label="Select Period"
                outlined
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="periodDateFilter" class="period-date-picker" scrollable range :type="dateFilterType" :key="dateFilterType">
              <v-row>
                <v-col cols="12">
                  <v-select
                    placeholder="Select presets"
                    :items="presetsForPeriodDatePicker"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                    clearable
                    hide-details
                    @change="selectedPeriodPreset"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row style="text-align: end">
                <v-col cols="12">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialog.save((periodDateFilter = null))"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(periodDateFilter)"> OK </v-btn>
                </v-col>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="px-2 ma-0">
        <v-col>
          <v-radio-group v-model="groupBy" row mandatory>
            <v-radio class="chargeback-report-group-by-website" label="By Website" value="website"></v-radio>
            <v-radio class="chargeback-report-group-by-service" label="By Service" value="service"></v-radio>
            <v-radio class="chargeback-report-group-by-date" label="By Date" value="createdAt"></v-radio>
            <v-radio class="chargeback-report-group-by-month" label="By Month" value="month"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selectedRows"
        :options.sync="options"
        :headers="tableColumns"
        :items="reportListTable"
        :item-class="chargebackReportItemClass"
        :server-items-length="totalReportListTable"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [50, 75, 100] }"
      >
        <template #[`item.totalEvents`]="{ item }">
          <div>{{ item.totalEvents }}</div>
        </template>
        <template #[`item.cost`]="{ item }">
          <div>{{ item.totalCost }} EUR</div>
        </template>
        <template #footer>
          <v-data-table
            class="report-table"
            :headers="tableTotalCountColumns"
            :items="totalCounts"
            :hide-default-footer="true"
            :hide-default-header="true"
            :item-class="highlightsTableRow"
          >
            <template #[`item.total`]="{}">
              <div class="total">Total</div>
            </template>
            <template #[`item.totalEvents`]="{ item }">
              <div class="total-events">{{ item.totalEvents }}</div>
            </template>
            <template #[`item.totalCost`]="{ item }">
              <div class="total-merchanto-gross">{{ (item.totalCost).toFixed(2) }} EUR</div>
            </template>
          </v-data-table>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { EventServiceEnum } from '@/enums/event-service.enum';
import { getDateFormat } from '@core/utils/filter';
import useChargebackReportsList from '@/views/chargebacks/chargeback-reports/useChargebackReportsList';

export default {
  setup() {
    const {
      tableTotalCountColumns,
      totalCounts,
      totalReportListTable,
      searchQuery,
      reportListTable,
      tableColumns,
      loading,
      options,
      reportTotalLocal,
      selectedRows,
      websites,
      serviceFilter,
      websiteFilter,
      headersSelected,
      serviceOptions,
      isAdmin,
      periodDateFilter,
      periodDateModal,
      presetsForPeriodDatePicker,
      merchantFilter,
      groupBy,
      dateFilterType,
    } = useChargebackReportsList();

    const selectedPeriodPreset = preset => {
      return (periodDateFilter.value = preset);
    };

    const highlightsTableRow = () => {
      return 'total-row';
    };

    const chargebackReportItemClass = () => {
      return 'chargeback-report-item';
    };

    const eventServiceEnum = EventServiceEnum;

    return {
      tableTotalCountColumns,
      totalReportListTable,
      totalCounts,
      merchantFilter,
      eventServiceEnum,
      isAdmin,
      headersSelected,
      searchQuery,
      websites,
      websiteFilter,
      serviceFilter,
      groupBy,
      serviceOptions,
      reportListTable,
      tableColumns,
      loading,
      options,
      reportTotalLocal,
      selectedRows,
      periodDateFilter,
      periodDateModal,
      presetsForPeriodDatePicker,
      selectedPeriodPreset,
      getDateFormat,
      highlightsTableRow,
      chargebackReportItemClass,
      dateFilterType,
    };
  },
  computed: {
    formattedWebsites() {
      return this.websites.map((website) => {
        if (website.status === 'inactive') {
          return { ...website, name: website.name + ' (inactive)' };
        }
        return website;
      });
    },
  },
};
</script>

<style lang="scss">
.theme--dark {
  .total-row {
    background-color: rgb(75 71 100);
  }
}

.theme--light {
  .total-row {
    background-color: rgb(232 231 234);
  }
}

.report-table {
  td {
    width: 155px;
  }
}

.v-date-picker-table.v-date-picker-table--month {
  padding: 0px 15px 0 9px!important;
}

.period-date-picker {
  > .v-card__actions {
    display: block !important;
    > div.row:last-child {
      text-align: end;
    }
  }
}
</style>
